import axios from "axios";
import NavBar from "../../NavBar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Changepswd=()=>{
    const token=localStorage.getItem("token");
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [old_pswd, setOld_pswd]=useState("");
    const [new_pswd, setNew_pswd]=useState("");
    const [conf_pswd, setConf_pswd]=useState("");
    const navigate=useNavigate();

    const changePassword=()=>{

        if(new_pswd!==conf_pswd)
        {
            toast.error("confim password and new password are not same ");
            return;
        }
        const obj={
    old_pass:old_pswd,
    new_pass:new_pswd
        }
        axios.post(`${BASE_URL}auth/change-password`,obj,
        {
            headers:{
                "x-access-token":`${token}`,
                "Content-Type":"Application/json",
                "version":"1.0.0"
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                toast.error(response.data.message);
            }else{
                toast.success(response.data.message);

            }
        })
    }

    
//   useEffect(()=>{

//     const token=localStorage.getItem("token");
//     if(token=="")
//     {
// navigate("/SignIn");
//     }
//   },[])
    return(
        <>
        <NavBar/>

<div className='container '>
    <div className='row d-flex justify-content-center align-items-center mt-5'>
        <div className='col-10 p-5' style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
      <div className="row">
      <div className="col-md-6">
            <label className="p-3">Old Password</label>
            <input type="text" className="form-control"
            name="old_pswd"
            value={old_pswd}
            onChange={(e)=>setOld_pswd(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <label className="p-3">New password</label>
            <input type="text" className="form-control" 
            name="new_pswd"
            value={new_pswd}
onChange={(e)=>setNew_pswd(e.target.value)}
       
            />

        </div>

        <div className="col-md-6">
            <label className="p-3">Confirm password</label>
            <input type="text" className="form-control"
            name="conf_pswd"
            value={conf_pswd}
            onChange={(e)=>setConf_pswd(e.target.value)}
            />
        </div>
       
      </div>
      <button type="submit" className="btn btn-sm btn-primary mt-3" style={{width:"60px"}} onClick={()=>changePassword()} >Save</button>
        </div>
    </div>


</div>
        </>
    );
}
export default Changepswd;