import NavBar from "../../../NavBar";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "multiselect-react-dropdown";
const UpdateProduct = () => {
  const { state } = useLocation();

  const [name, setName] = useState(state.item.name);
  const [mrp, setMrp] = useState(state.item.mrp);
  const [quantity, setquantity] = useState(state.item.quantity);
  const [price, setPrice] = useState(state.item.price);
  const [shortdes, setShortdes] = useState(state.item.short_decription);
  const [longdes, setLongdes] = useState(state.item.long_decription);
  const [file, setFile] = useState("");
  const [banner, setBanner] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [howtouseid, setHowtouseId] = useState(state.item.stepstouse);
  const [bestsellcheck, setBestsellcheck] = useState(state.item.bestseller);
  const [status, setStatus] = useState(state.item.status);
  const [benefits, setBenefits] = useState(state.item.benefits);
  const [howtouse, setHowtouse] = useState(state.item.howtouse);
  const [Ingredient, setIngredients] = useState(state.item.ingredients);
  const [allChildSubCategory, setAllChildSubCategory] = useState("");
  const [selectedSteps, setSelectedSteps] = useState([]);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allcategory, setAllCategory] = useState([]);
  const [allsubcategory, setAllSubCategory] = useState([]);
  const [allHowtouse, setAllHowtouse] = useState([]);
  const [homecategory, setHomeCategory] = useState([]);
  const [hcategory, setHcategory] = useState(state.item.HomeCategoryId);
  const [ctgryId, setCtgryId] = useState(state.item.CategoryId);
  const [scategoryId, setScategoryId] = useState(state.item.SubCategoryId);
  const [childCateId, setChildCateId] = useState(state.item.ChildCategoryId);
  const [childCName, setChildCName] = useState(
    state.item.childCategory_details[0].name
  );

  const [id, Id] = useState(state.item._id);

  const token = localStorage.getItem("token");
  useEffect(() => {
    getChildcat();
  }, [scategoryId]);
  const getAllCat = () => {
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          setAllCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubCategory = () => {
    axios
      .post(
        `${BASE_URL}getSubCategoryByCategoryId/${ctgryId}`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            Version: "1.0.0",
          },
        }
      )
      .then(function (response) {
        if (response.data.success == false) {
          setAllSubCategory("");
        } else {
          setAllSubCategory(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getSelectedHowtouse = () => {
    axios
      .get(`${BASE_URL}admin/getHowToUsebyids/${id}`, {
        headers: {
          "Content-type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
        } else {
          setSelectedSteps(response.data.data);
          console.log("setSelectedSteps", response.data.data);
        }
      });
  };

  const getChildcat = () => {
    if (scategoryId) {
      console.log("in");
      axios
        .get(`${BASE_URL}admin/childcategorybysubcategory/${scategoryId}`, {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        })
        .then((response) => {
          if (response.data.success == false) {
            console.log(response.data.message);
          } else {
            setAllChildSubCategory(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getallhomecategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllHomeCategory`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          setHomeCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const UpdateProduct = () => {
    const token = localStorage.getItem("token");
    const fromData = new FormData();
    for (let i = 0; i < howtouseid.length; i++) {
      if (!howtouseid[i]._id) {
        fromData.append("stepstouse", howtouseid[i]);
      } else {
        fromData.append("stepstouse", howtouseid[i]._id);
      }
    }
    fromData.append("howtouse", howtouse);
    fromData.append("name", name);
    fromData.append("mrp", mrp);
    fromData.append("price", price);
    fromData.append("quantity", quantity);
    fromData.append("short_decription", shortdes);
    fromData.append("long_decription", longdes);
    fromData.append("benefits", benefits);
    fromData.append("bestseller", bestsellcheck);

    fromData.append("ingredients", Ingredient);
    fromData.append("image", file[0]);
    // if (banner[0] != undefined) fromData.append("banner", banner[0]);
    if (banner != undefined) {
      fromData.append("banner", banner[0]);
    } else {
      fromData.append("banner", "");
    }

    fromData.append("status", status);
    fromData.append("CategoryId", ctgryId);
    fromData.append("SubCategoryId", scategoryId);
    fromData.append("HomeCategoryId", hcategory);
    fromData.append("ChildCategoryId", childCateId);
    setLoading(true);
    axios
      .put(`${BASE_URL}admin/updateProduct/${id}`, fromData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          setLoading(false);
          toast.error(response.data.message);
        } else {
          setLoading(false);
          toast.success(response.data.message);
          setName("");
          setPrice("");
          setMrp("");
          setShortdes("");
          setLongdes("");
          navigate("/ProductListing");
        }
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  };

  const getAllHowtouse = () => {
    axios
      .get(`${BASE_URL}admin/getAllHowToUse`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          setAllHowtouse(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllCat();
    getallhomecategory();
    getAllHowtouse();
    getSelectedHowtouse();

    console.log("bestseller check", bestsellcheck);
  }, []);

  const Fntosetsubc = (value, getSubCategory) => {
    // Your existing logic to set the category id
    // ...
    console.log("I changed");
    setCtgryId(value);
    // Call the callback function after updating the state
    if (getSubCategory && typeof getSubCategory === "function") {
      getSubCategory();
    }
  };

  const handleMultiselectChange = (selectedList) => {
    setHowtouseId(selectedList);
  };
  useEffect(() => {
    getSubCategory();
  }, [ctgryId]);

  const Fntosetchildc = (value, getChildcat) => {
    // Your existing logic to set the category id
    // ...
    setScategoryId(value);
    console.log("Sub Category Id", value);
    if (getChildcat && typeof getChildcat === "function") {
      getChildcat();
    }
  };

  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <h1 className="text-center">Update Product</h1>

            <div className="row">
              <div className="col-md-6">
                <label className="formleb pt-3"> Category</label>
                <select
                  name="category"
                  className="form-control forminp"
                  value={ctgryId}
                  onChange={(e) => Fntosetsubc(e.target.value, getSubCategory)}
                >
                  <option value="">--select--</option>
                  {allcategory.length > 0 &&
                    allcategory.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3"> Subcategory</label>
                <select
                  name="subcatgeory"
                  className="form-control forminp"
                  value={scategoryId}
                  onChange={(e) => Fntosetchildc(e.target.value, getChildcat)}
                >
                  <option value="">--select--</option>
                  {allsubcategory.length > 0 &&
                    allsubcategory.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3"> ChildCategory</label>
                <select
                  name="subcatgeory"
                  className="form-control forminp"
                  value={childCateId}
                  onChange={(e) => setChildCateId(e.target.value)}
                >
                  <option value="">--select--</option>
                  {allChildSubCategory.length > 0 &&
                    allChildSubCategory.map((item, index) => (
                      <option
                        selected={childCName == item.name ? true : false}
                        value={item._id}
                        key={index}
                      >
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3"> HomeCategory</label>
                <select
                  name="homecategory"
                  className="form-control forminp"
                  value={hcategory}
                  onChange={(e) => setHcategory(e.target.value)}
                >
                  <option value="">--select--</option>
                  {homecategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3">Step To Use </label>
                <Multiselect
                  options={allHowtouse}
                  displayValue="name"
                  onSelect={handleMultiselectChange}
                  onRemove={handleMultiselectChange}
                  selectedValues={selectedSteps}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Product Name</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3">Price</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Quantity</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="quantity"
                  value={quantity}
                  onChange={(e) => setquantity(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3"> Images</label>
                <input
                  type="file"
                  className="form-control forminp"
                  name="file"
                  onChange={(e) => setFile(e.target.files)}
                />

                <div className="row">
                  <div className="col-2 col-xs-2">
                    <img
                      src={state.item.image}
                      className="img-fluid"
                      width="70px"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3"> Banner</label>
                <input
                  type="file"
                  className="form-control forminp"
                  name="banner"
                  onChange={(e) => setBanner(e.target.files)}
                />

                <div className="row">
                  <div className="col-2 col-xs-2">
                    <img
                      src={state.item.banner}
                      className="img-fluid"
                      width="70px"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Short Description</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  data={shortdes}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShortdes(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Long Description</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  data={longdes}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLongdes(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Benefits</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write benefits here."

                  data={benefits}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setBenefits(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">How To Use</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  data={howtouse}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setHowtouse(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3">Ingredients</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write Ingredients here."

                  data={Ingredient}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setIngredients(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>

              <div className="col-md-6 pt-3">
                <label className="formleb">Status</label>
                <select
                  name="status"
                  className="forminp form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="select">--select--</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">
                  Check mark if you want to add this product in
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setBestsellcheck(e.target.checked)}
                    checked={bestsellcheck}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <button
                type="submit"
                className="formbtn  mt-3 ms-3 p-2"
                onClick={() => UpdateProduct()}
              >
                Save{loading && <Spinner animation="border" size="sm" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateProduct;
