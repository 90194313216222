import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
const Howtouse = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [allHowtouse, setAllHowtouse] = useState([]);
  const token = localStorage.getItem("token");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const getAllHowtouse = () => {
    axios
      .get(`${BASE_URL}admin/getAllHowToUse`,
        {
          headers: {
            "Content-Type": "Application/json",
            "version": "1.0.0",
            "x-access-token": `${token}`
          }
        }).then((response) => {
          if (response.data.success == false) { console.log(response.data.message) }
          else {
            setAllHowtouse(response.data.data);
            console.log(response.data.data);

          }

        }).catch((error) => {
          console.log(error);
        })
  }

  const addHowtouse = () => {
    if (name == "" || name.length == 0) {
      toast.error("step name is required");
      return;
    }
    if (file[0] == null || file[0] == undefined) {
      toast.error("Please select image/icon to show the step.");
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("status", status);

    formData.append("image", file[0]); fileInputRef.current.value = "";
    
    axios
      .post(`${BASE_URL}admin/addHowToUse`, formData,
        {
          headers: {
            "x-access-token": `${token}`,
            "version": "1.0.0",
            "Content-Type": "multipart/form-data"


          }
        }).then((response) => {
          if (response.data.success == false) {
            // console.log(resp)
          } else {
            toast.success(response.data.message);
            setName("");
            fileInputRef.current.value = "";
            setStatus("");
            getAllHowtouse();
          }
        }).catch((error) => {
          console.log(error);
        })
  }


  const deleteHowtouse = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {

    } else {

      return;
    }
    setId(id);
    axios
      .delete(`${BASE_URL}admin/deleteHowToUse/${id}`,
        {
          headers: {
            "version": "1.0.0",
            "Content-Type": "Application/json",
            "x-access-token": `${token}`
          }
        }).then((response) => {
          if (response.data.success == false) {

          } else {
            toast.error(response.data.message);
            getAllHowtouse();
          }
        }).catch((error) => {
          console.log(error);
        })
  }


  useEffect(() => {
    getAllHowtouse();
  }, [])
  // const ShowModel=(id)=>{
  // setId(id);
  // setShow(true);
  // }



  return (
    <>

      <NavBar />
      <Container fluid >
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className='p-3'>
              <Row className='' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2" style={{ fontSize: '25px' }}>
                    How to use

                  </div>
                  <label className='formleb'> Name</label>
                  <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">

                    <Form.Control type="text" placeholder="Enter Name" className='text'
                      name='name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <label className='formleb'> Image</label>
                  <input type='file' className='forminp'
                    name='file'
                    ref={fileInputRef}
                    onChange={(e) => setFile(e.target.files)}
                  />

                  <label className="formleb"> Status</label>

                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>


                  </select>


                  <button type="button" className="btn btn-primary mt-3" onClick={() => addHowtouse()}> Save </button>

                </div>
              </Row>


            </Col>
            <Col md={2}>
            </Col>
          </Row>

          <div className="scrollit">
            <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2"> Listing</span>
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>
            <div style={{ overflowX: "scroll", scrollBehavior: "smooth" }}>
              <Table striped bordered hover >
                <thead>
                  <tr>
                    <th>#</th>
                    <th> Image</th>

                    <th> Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    allHowtouse.length > 0 && allHowtouse.map((item, index) => (
                      <tr>
                        <td>{++index}</td>
                        <td><Image src={item.image} style={{ width: '40px' }}></Image></td>

                        <td>{item.name}</td>
                        <td>{item.status}</td>
                        <td>
                          <button type="button" class="btn btn-primary " onClick={() => navigate('/edithowtouse', { state: { item } })}><i className="fa fa-edit"></i> </button>
                          &nbsp; &nbsp;
                          <button type="button" class="btn btn-danger " onClick={() => deleteHowtouse(item._id)}><i className='fa fa-trash' ></i> </button>
                        </td>
                      </tr>
                    ))
                  }


                  {/* <tr>
                  <td>3</td>
                
                  <td>gujh</td>
                  <td>Active</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
                </tbody>
              </Table>
            </div>

          </div>
        </Container>
      </Container>

      {/* <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Home Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Control type="text" placeholder="Enter Name" className='text' 
        name='name'
        value={name}
        onChange={(e)=>setName(e.target.value)}
        />

<label className='formleb'>Select image</label>
      <input type='file' className='forminp'
      name='file'
      onChange={(e)=>setFile(e.target.files)}
      />

<label className="formleb">Select Status</label>

<select className="forminp"
name='status'
value={status}
onChange={(e)=>setStatus(e.target.value)}
>
<option >--Select status--</option>

  <option value="Active">Active</option>
  <option value="Inactive">Inactive</option>


</select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>UpdateHowToUse()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
export default Howtouse;
