import { useEffect, useState } from "react";
// import Dashboard from "../../Dashboard";
import { Button, Modal, Table } from "react-bootstrap";
import NavBar from "../../../NavBar";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

const NewOrder=()=>{
    const [newOrders , setNewOrders]=useState([]);
    const navigate= useNavigate();
    const [show, setShow] = useState(false);
    const [status , setStatus]=useState("")
  const [id, setId]=useState("");
  const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState();

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const getNewOrders=()=>{
        const token=localStorage.getItem("token");
            const currentDate= new Date();
           
            let newD=moment(currentDate).format('YYYY-MM-DD')
              axios
              .get(`${BASE_URL}admin/getNewOrders/${currentPage}`,{
                headers:{
                  "x-access-token": `${token}`,
                  "version":"1.0.0",
                  "Content-type":"Application/json"
                }
              }).then((response)=>{
                if(response.data.success==false)
                {
                  console.log(response.data.message);
                }else{
                    // setNewOrders.filter((itemresponse.data.data);
                    // console.log("for today ",moment(response.data.data[11].createdAt).format('YYYY-MM-DD')==newD);
                    // let  newdata=[];
                    // data=response.data.data.find(data => moment(data.createdAt).format('YYYY-MM-DD')==newD);
                    // newdata= response.data.data.filter((data)=>moment(data.createdAt).format('YYYY-MM-DD')==newD);
                    setNewOrders(response.data.data);
                    setPages(response.data.remainingCount);
                    // console.log("newOrderssss===>",[response.data.data.find(data => moment(data.createdAt).format('YYYY-MM-DD')==newD)])
                    
                    // setNewOrders(response.data.data.filter((item)=>item.createdAt==currentDate))
                    // console.log("neworder-->",response.data.data)

                  // console.log("orders---->",newdata);
                }
              }).catch((error)=>{
                console.log(error);
              })
            }


            useEffect(()=>{
            
                if(!(localStorage.getItem("token"))){
                  navigate("/SignIn");
                }
              },[])


              const openModel=(id)=>{
                setShow(true);
                setId(id);
              }


              const changeStatus=()=>{
                const token=localStorage.getItem("token");
                      const obj={
                        orderID:id,
                        status:status
                      }
                   axios
                   .post(`${BASE_URL}admin/changeStatus`,obj,{
                    headers:{
                      "Content-Type":"Application/json",
                      "x-access-token":`${token}`,
                      "version":"1.0.0"
                    }
                   }).then((res)=>{
                    if(res.data.success){
                      toast.success(res.data.message);
                      getNewOrders();
                    }else{
                    toast.error(res.data.message);
                    }
                   }).catch((err)=>{
                    console.log(err.message);
                   })
                    }


                    const handlePageChange = (page) => {
                      setCurrentPage(page);
                      getNewOrders(page);
                    
                    }

                    useEffect(()=>{
                      getNewOrders();
                    },[currentPage]);

    return(
        <>

 <NavBar />
       <div className='container '>
        <br/>
        <br/>
       <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2">New Order List</span>
          <div className="scrollit">
          
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>
            <Table striped bordered hover >
              <thead>
                <tr>
                
                  {/* <th>#</th> */}
                  <th>S no.</th>
                  <th> Order Id</th>
                  <th> Customer name</th>
                  <th> delivery_type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>

              {
        newOrders.length>0&&newOrders.map((item, index)=>(
            <>
              <tr  key={index}>
          <td >    {(currentPage > 1 ? (currentPage - 1) * 10 : 0) + index + 1}</td>
          <td >{item.user_Order_ID}</td>
          <td >{item.customer_name}</td>
          <td >{item.delivery_type}</td>
          <td >{item.total_amount}</td>
          <td >{item.status}</td>


          <td class="ordertTd" data-label="Actions" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <button class="btn btn-primary btn-sm" onClick={()=>navigate("/order-details" ,{state:item})} ><i className='fa fa-eye'></i> </button>&nbsp;&nbsp;
            <button class="btn btn-primary btn-sm"  onClick={()=>navigate("/print-invoice", { state: item })}><i className='fa fa-print'></i> </button>&nbsp;&nbsp;
            <button class="btn btn-primary btn-sm" style={{fontSize:"12px"}}   onClick={()=>openModel(item._id)}>Change <br/>status </button>
            </td>
        </tr>
            </>
          ))
        }

                {/* <tr>
                  <td>2</td>
                  <td><Image src="#" style={{ width: '40px' }}></Image></td>
                  <td>Thornton</td>
                  <td>InActive</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
                {/* <tr>
                  <td>3</td>
                  <td ><Image src="#" style={{ width: '40px' }}></Image></td>
                  <td>gujh</td>
                  <td>Active</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
              </tbody>


                {/* pagination  */}
                {
                  pages>=1&&    <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
                      <a class="page-link" href="#" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                  
  
                    {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                      <>
                        <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
                          <button className="page-link" onClick={() => handlePageChange(page)}>
                            {page}
                          </button>
                        </li>
                      </>
                    ))}
                    <li class={`page-item ${currentPage === pages ? "disabled" : ""}`}>
                      <a class="page-link" href="#" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
                }
            
              {/* pagination end  */}
            </Table>
            {
              pages?"":<div className="text-center "><div className="p-5">No record found</div></div>
            }
          </div>


       
      </div>

      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change order status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <label className="mt-3 mb-1" >
            Select Status:
          </label>
          <select className="form-control text " onChange={(e)=>setStatus(e.target.value)}>
            <option value="">--select--</option>
            <option value="Pending">Pending</option>
            <option value="Process">Process</option>
            <option value="Shipped">Shipped</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Delivered">Delivered</option>
            <option value="Return">Return</option>
            <option value="Replace">Replace</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>changeStatus()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
     </>
    );
} 
export default NewOrder;