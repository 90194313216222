import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
const SubCategory = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getallsubCategory, setGetAllSubcategory] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [name, setName] = useState("");
  const [categoryid, setCategoryId] = useState("");
  const [status, setStatus] = useState("Active");
  const navigate = useNavigate();
  useEffect(() => {
    getAllSubCategory();
    getAllCat();
  }, [])
  const getAllSubCategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllSubCategory`, {

        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          "Version": "1.0.0"
        },

      })
      .then(function (response) {
        if (response.data.success) {
          setGetAllSubcategory(response.data.data);
          // console.log("setGetAllSubcategory", response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      })
  }


  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          "version": "1.0.0",
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success) {
          setGetAllCategory(response.data.data);
          console.log("getallcategory", response.data.data)
        } 
      }).catch((error) => {
        console.log(error);
      })
  }

  const save = () => {
    if (!categoryid) {
      toast.error("Kindly Select Category");
      return;
    }
    if (name.length == 0 && name == "") {
      toast.error("Home Category name is required");
      return;
    }

    const token = localStorage.getItem("token");
    axios
      .post(`${BASE_URL}admin/addSubCategory`, {
        CategoryId:categoryid,
        name:name,
        status:status
      },
        {
          headers: {
            "x-access-token": `${token}`,
            " Content-Type": "Application/json",
            "version": "1.0.0"
          }
        }).then((response) => {
          if (response.data.success === false) {
            toast.error(response.data.message);
          }
          else {
            toast.success(response.data.message);
            setName("");
            setCategoryId("");
            setStatus("");
            // getAllCat();

            getAllSubCategory();
          }
        })
  }
  const deleteSubcategory = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {

    } else {

      return;
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteSubCategory/${id}`, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getAllSubCategory();
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  return (
    <>
      <NavBar />
      <Container fluid style={{ height: '100vh' }}>
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className='p-3' >
              <Row style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2 heading" style={{ fontSize: '25px' }}>
                    Sub Category
                  </div>
                  <br />
                  <label className='formleb'> Category</label>
                  <Form.Select aria-label="Default select example" className='mb-3 select forminp'

                    name="categoryid"
                    value={categoryid}
                    onChange={(e) => setCategoryId(e.target.value)}>
                    <option> --select Category --</option>
                    {
                      getallcategory.map((item, index) => (
                        <option value={item._id} key={index}


                        >{item.name}</option>
                      ))
                    }
                  </Form.Select>
                  <label className='formleb'>Name</label>

                  <input type='text' className='form-control mb-3 forminp'
                    placeholder='Enter Name'
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}

                  />
                 
                  <label className="formleb">Status</label>

                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>


                  </select>

                  {/* <input type="file" className="form-control file" ></input> */}
                  <button type="button" className="btn btn-primary mt-3 formbtn" onClick={() => save()}> Save </button>

                </div>
              </Row>


            </Col>
            <Col md={2}>
            </Col>
          </Row>
          <Row>
            <Col className='col-md-1'></Col>
            <Col>
              <div className="scrollit">
                <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2 heading">Subcategory Listing</span>
                {/* <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr> */}
                <div className='' style={{ overflowX: "auto" }}>
                  <Table striped bordered hover className='mt-3'>
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th>Category</th>
                        <th> Name</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getallsubCategory.map((item, index) => (
                          <tr key={index}>

                            <td>{++index}</td>
                            <td>{item.category_details[0]?.name}</td>
                            <td>{item.name}</td>
                            <td>{item.status}</td>



                            <td>
                              <button type="button" className="btn btn-primary " onClick={() => navigate('/edit-subcategory', { state: { item } })}><i className="fa fa-edit"></i> </button>
                              &nbsp; &nbsp;
                              <button type="button" className="btn btn-danger "><i className='fa fa-trash' onClick={() => deleteSubcategory(item._id)}></i> </button>
                            </td>
                          </tr>

                        )
                        )
                      }


                      {/* <tr>
                  <td>2</td>
                  <td>category</td>
                  <td>Thornton</td>
                  <td>InActive</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
                      {/* <tr>
                  <td>3</td>
                  <td >category</td>
                  <td>gujh</td>
                  <td>Active</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
                    </tbody>
                  </Table>
                </div>

              </div>
            </Col>
            <Col className='col-md-1'></Col>

          </Row>

        </Container>
      </Container>

      {/* 
      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update SubCategory</Modal.Title>
        </Modal.Header>
        <div className='p-3'>
        <Form.Select aria-label="Default select example" className='select ' style={{backgroundColor:'#f8f9fe',borderRadius:'5px',padding:'10px'}}
                
                name="categoryid"
                value={categoryid}
                onChange={(e)=>setCategoryId(e.target.value)}>
      <option> --select Category --</option>
      {
        getallcategory.map((item,index)=>(
          <option value={item._id} key={index}
      
          
          >{item.name}</option>
        ))
      }
    </Form.Select>

    

<label className='formleb'>Enter name</label>
    
    <input type='text' className='form-control mb-3 forminp' 
    placeholder='Name'
    name="name"
    value={name}
    onChange={(e)=>setName(e.target.value)}
    
    />
      <label className='formleb'>Select Image</label>
<input type="file" className="forminp form-control file mb-3"
                  name="file"
                  onChange={(e) => setFile(e.target.files)}
                ></input>

<label className='formleb'>Enter Heading</label>
<input type='text' className='form-control mb-3 forminp' 
    placeholder='Enter heading'
    name="heading"
    value={heading}
    onChange={(e)=>setHeading(e.target.value)}
    
    />
    <label className='formleb'>Enter sub-heading</label>
<input type='text' className='form-control mb-3 forminp' 
    placeholder='Enter Sub-heading'
    name="subheading"
    value={subheading}
    onChange={(e)=>setSubheading(e.target.value)}
    
    />
<label className='formleb'>Enter description</label>
<input type='text' className='form-control mb-3 forminp' 
    placeholder='Enter description'
    name="description"
    value={description}
    onChange={(e)=>setDescription(e.target.value)}
    
    />

<label className="formleb">Select Status</label>

<select className="forminp"
name='status'
value={status}
onChange={(e)=>setStatus(e.target.value)}
>
<option >--Select status--</option>

  <option value="Active">Active</option>
  <option value="Inactive">Inactive</option>


</select>
    </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
          
          <Button variant="primary" onClick={()=>UpdatesubCategory()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}


    </>
  );
}
export default SubCategory;