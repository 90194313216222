
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
// import Table from 'react-bootstrap/Table';
// import Image from 'react-bootstrap/Image';
// import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate ,useLocation} from "react-router-dom";



const SubCategory = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // const [getallsubCategory, setGetAllSubcategory] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [name, setName] = useState(state.item.name);
  
  const [categoryid, setCategoryId] = useState(state.item.CategoryId);
  const [status, setStatus] = useState(state.item.status);
  const [id, setId] = useState(state.item._id);
  // const [file, setFile] = useState("");
 

 
  useEffect(() => {
    console.log("value",state);
    getAllCat();
  }, [])
  const getAllCat=()=>{
    const token=localStorage.getItem("token");
    axios
    .get(`${BASE_URL}admin/getAllCategory`,{
      headers:{
        "x-access-token":`${token}`,
        "version":"1.0.0",
        "Content-Type":"multipart/form-data"
      }
    }).then((response)=>{
      if(response.data.success==false){
        // toast.error(response.data.message);
      }else{
        // toast.success(response.data.message);
        setGetAllCategory(response.data.data);
        console.log("getallcategory",response.data.data)
      }
    }).catch((error)=>{
      console.log(error);
    })
  }

  const UpdatesubCategory = () => {
    // const formData = new FormData();
    // formData.append("CategoryId", categoryid);
    // formData.append("name", name);
    // // formData.append("image", file[0]);
    // // formData.append("heading1", heading);
    // // formData.append("heading2", subheading);
    // // formData.append("description", description);
    // formData.append("status", status);
    const token = localStorage.getItem("token");
    axios
      .put(`${BASE_URL}admin/updateSubCategory/${id}`, {
        CategoryId:categoryid,
        name:name,
        status:status
      }, {
        headers: {
          "x-access-token": `${token}`,
          " Content-Type": "Application/json",
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        }
        else {
          toast.success(response.data.message);
          navigate("/SubCategory");
          setName("");
          
        }
      }).catch((error) => {
        console.log(error);
      })
  }



  return (
    <>
      <NavBar />
      <Container fluid style={{ height: '100vh' }}>
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className='p-3' >
              <Row style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2 heading" style={{ fontSize: '25px' }}>
                    Sub Category

                  </div>
                  <br />
                  <label className='formleb'> Category</label>
                  <Form.Select aria-label="Default select example" className='mb-3 select forminp'

                    name="categoryid"
                    value={categoryid}
                    onChange={(e) => setCategoryId(e.target.value)}>
                    <option > --select Category --</option>
                    {
                      getallcategory.map((item, index) => (
                        <option value={item._id} key={index}


                        >{item.name}</option>
                      ))
                    }
                  </Form.Select>
                  <label className='formleb'> name</label>

                  <input type='text' className='form-control mb-3 forminp'
                    placeholder='Enter Name'
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}

                  />
                 
                  <label className="formleb"> Status</label>
                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >--Select status--</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  <button type="button" className="btn btn-primary mt-3 formbtn" onClick={() => UpdatesubCategory()}> Update  </button>

                </div>
              </Row>


            </Col>
            <Col md={2}>
            </Col>
          </Row>
        </Container>
      </Container>


   


    </>
  );
}
export default SubCategory;