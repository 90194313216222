import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
const EditHomeCategory = () => {
  const { state } = useLocation();

  const [name, setName] = useState(state.item.name);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // const [gethomeCategory , setGethomeCategory]=useState([]);
  // const [show, setShow] = useState(false);
  const [file, setFile] = useState("");
  const [id, setId] = useState(state.item._id);
  const [status, setStatus] = useState(state.item.status);
  const navigate = useNavigate()
  // const handleShow = (id) =>
  // {
  //   setId(id);
  //   setShow(true);
  // }
  useEffect(() => {
    console.log('value:', state)
    // getallhomecategory();
  }, [])







  const updateHomeCategory = () => {
    // console.log("i have entered in it");
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("status", status);
    formData.append("image", file[0]);
    // formData.append
    axios
      .put(`${BASE_URL}admin/updateHomeCategory/${id}`, formData, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          // setShow(false);

          toast.success(response.data.message);
          // getallhomecategory();

          navigate('/HomeCategory')
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  // const saveChanges=()=>{
  //   updateHomeCategory();

  //   setShow(false)
  // }
  return (
    <>

      <NavBar />
      <Container fluid >
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className='p-3'>
              <Row className='' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2" style={{ fontSize: '25px' }}>
                    Home Category

                  </div>
                  <label className='formleb'>Enter Name</label>
                  <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">

                    <Form.Control type="text" placeholder="Enter Name" className='text'
                      name='name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <label className='formleb'> Image</label>
                  <input type='file' className='forminp'
                    name='file'
                    onChange={(e) => setFile(e.target.files)}
                  />

                  <img src={state.item.image} className="img-fluid" width="100px" />
                  <br />
                  <label className="formleb"> Status</label>
                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>


                  </select>

                  <button type="button" className="btn btn-primary mt-3" onClick={() => updateHomeCategory()}> Save </button>

                </div>
              </Row>


            </Col>
            <Col md={2}>
            </Col>
          </Row>


        </Container>
      </Container>


    </>
  );
}
export default EditHomeCategory;