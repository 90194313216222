import { useEffect, useState } from "react";
import NavBar from "../../NavBar";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const [sidebanner1, setSidebanner1] = useState("");
  const [sidebanner_url1, setSidebanner_url1] = useState("");

  const [sidebanner2, setSidebanner2] = useState("");
  const [sidebanner_url2, setSidebanner_url2] = useState("");

  const [footerbanner, setFooterbanner] = useState("");
  const [footerbanner_url, setFooterbanner_url] = useState("");
  const [id, setId] = useState("");
  const [topbanner, setTopBanner] = useState([]);
  const navigate = useNavigate();
  // const maxFileSize=3000000;
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const token = localStorage.getItem("token");

  const getbanner = () => {
    axios
      .get(`${BASE_URL}admin/getBanner`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if ((response.data.success = false)) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          setTopBanner(response.data.data);
          console.log("banner:", response.data.data);
          if (response.data.data._id) {
            setId(response.data.data._id);
            setSidebanner1(response.data.data.sideBanner1);
            setSidebanner_url1(response.data.data.sideBannerUrl1);
            setSidebanner2(response.data.data.sideBanner2);
            setSidebanner_url2(response.data.data.sideBannerUrl2);
            setFooterbanner(response.data.data.footerBanner);
            setFooterbanner_url(response.data.data.footerBannerUrl);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // useEffect(()=>{
  //     console.log("bannerid",id);
  // })

  const addbanner = () => {
    if (!sidebanner1[0]) {
      if (!sidebanner2[0]) {
        if (!footerbanner[0]) {
          toast.error("upload banner failed");
          return;
        }
      }
    }
    const formData = new FormData();
    formData.append("sideBanner1", sidebanner1[0]);
    formData.append("sideBannerUrl1", sidebanner_url1);
    formData.append("sideBanner2", sidebanner2[0]);
    formData.append("sideBannerUrl2", sidebanner_url2);
    formData.append("footerBanner", footerbanner[0]);
    formData.append("footerBannerUrl", footerbanner_url);
    if (id) {
      formData.append("id", id);
    }

    axios
      .post(`${BASE_URL}admin/addBanner`, formData, {
        headers: {
          "Content-Type": "multipart/formdata",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          // toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          getbanner();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getbanner();

    // console.log("banner",topbanner);

    // console.log("id",topbanner._id);
  }, []);
  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="row">
              <div className="col-md-6">
                <label className="formleb">Side Banner Url 1</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="sidebanner_url1"
                  value={sidebanner_url1}
                  onChange={(e) => setSidebanner_url1(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Side Banner Image 1</label>
                <input
                  type="file"
                  className="form-control forminp"
                  accept=".png , .jpeg , .jpg ,"
                  name="sidebanner1"
                  // value={sidebanner1}

                  onChange={(e) => setSidebanner1(e.target.files)}
                />
                <img src={sidebanner1} className="img-fluid" width="100px" />
              </div>

              <div className="col-md-6">
                <label className="formleb"> Side Banner Url 2</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="sidebanner_url2"
                  value={sidebanner_url2}
                  onChange={(e) => setSidebanner_url2(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Side Banner Image 2</label>
                <input
                  type="file"
                  className="form-control forminp"
                  accept=".png , .jpeg , .jpg ,"
                  name="sidebanner2"
                  onChange={(e) => setSidebanner2(e.target.files)}
                />
                <img src={sidebanner2} className="img-fluid" width="100px" />
              </div>

              <div className="col-md-6">
                <label className="formleb">Footer Banner Url 3</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="footerbanner_url"
                  value={footerbanner_url}
                  onChange={(e) => setFooterbanner_url(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Footer Banner Image 3</label>
                <input
                  type="file"
                  className="form-control forminp"
                  accept=".png , .jpeg , .jpg ,"
                  name="footerbanner"
                  onChange={(e) => setFooterbanner(e.target.files)}
                />

                <img src={footerbanner} className="img-fluid" width="100px" />
              </div>

              <button
                type="button"
                className="formbtn btn btn-sm btn-primary w-100 mt-3 p-2"
                onClick={() => addbanner()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
