import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
const HomeCategory = () => {
  const [name, setName] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const fileInputRef = useRef(null);
  const [gethomeCategory, setGethomeCategory] = useState([]);
  // const [show, setShow] = useState(false);
  const [file, setFile] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    getallhomecategory();
  }, [])

  const AddHomeCategory = () => {
    const token = localStorage.getItem("token");
    if(name.length==0&&name==""){
      toast.error("Home Category name is required");
      return;
    }
    if(file[0]==undefined||file[0]==""){
      toast.error("Home Category Image  is required");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", file[0]);
    formData.append("status", status);

    axios
      .post(`${BASE_URL}admin/addHomeCategory`, formData, {
        headers: {
          "x-access-token": `${token}`,
          "version": "1.0.0",
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
        setName("")
        setStatus("");
        fileInputRef.current.value = "";

        }
        getallhomecategory();
      }).catch((error) => {
        console.log(error);
      })
  }


  const getallhomecategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllHomeCategory`, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success == false) {
          // toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }

        } else {
          // toast.success(response.data.message);
          console.log("response.data.data all home category", response.data.data)
          setGethomeCategory(response.data.data);

        }
      }).catch((error) => {
        console.log(error)
      })
  }


  const deletehomecategory = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {
     
    } else {
     
        return ;
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteHomeCategory/${id}`, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }

        } else {
          toast.error(response.data.message);
          getallhomecategory();

        }
      }).catch((error) => {
        console.log(error)
      })
  }

  
  return (
    <>

      <NavBar />
      <Container fluid >
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className='p-3'>
              <Row className='' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2" style={{ fontSize: '25px' }}>
                    Home Category

                  </div>
                  {/* <label className='formleb'>Enter Name</label>
                  <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">

                    <Form.Control type="text" placeholder="Enter Name" className='text'
                      name='name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group> */}

                  <label className='formleb'>Name</label>
                  <input type='text' className='forminp'
                    name='name'
                    value={name}
                    placeholder='Enter name'
                      onChange={(e) => setName(e.target.value)}
                  />
                  <label className='formleb'> Image</label>
                  <input type='file' className='forminp'
                    name='file'
                    ref={fileInputRef} 
                    onChange={(e) => setFile(e.target.files)}
                  />


                  <label className="formleb"> Status</label>

                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>


                  </select>

                  <button type="button" className="btn btn-primary mt-3" onClick={() => AddHomeCategory()}> Save </button>

                </div>
              </Row>


            </Col>
            <Col md={2}>
            </Col>
          </Row>

          <div className="scrollit">
            <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2">Home Category Listing</span>
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>
            <div className='' style={{overflowX:"scroll"}}>
            <Table striped bordered hover >
              <thead>
                <tr>
                  <th>#</th>
                  <th> Image</th>

                  <th> Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  gethomeCategory.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td><Image src={item.image} style={{ width: '40px' }}></Image></td>


                      <td>{item.name}</td>
                      <td>{item.status}</td>
                      <td>
                        <button type="button" className="btn btn-primary "><i className="fa fa-edit"
                          onClick={() => navigate('/edit_homecategory', { state: { item } })}
                        ></i> </button>
                        &nbsp; &nbsp;
                        <button type="button" className="btn btn-danger "><i className='fa fa-trash'
                          onClick={() => deletehomecategory(item._id)}
                        ></i> </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            </div>
           
          </div>
        </Container>
      </Container>


    </>
  );
}
export default HomeCategory;