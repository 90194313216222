import { Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useNavigate, useLocation } from "react-router-dom";
const EditCategory = () => {
  const { state } = useLocation();
  // const 
  // const navigate = useNavigate()
  const [name, setName] = useState(state.item.name);
  const [status, setStatus] = useState(state.item.status);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [id, setId] = useState(state.item._id);
  const navigate = useNavigate();
  // const UpdCategory = (id) => {
  //   // updateCategory(id)
  //   setId(id);

  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }

  useEffect(() => {
    // console.log("value", state.item.name);

    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {

      // navigate("/SignIn");
    }
  }, [token])

  const updateCategory = async (id) => {
    axios
      .post(`${BASE_URL}admin/updateCategory/${id}`,
        {
          name: name,
          status: status
        }, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          "version": "1.0.0"
        },

      })
      .then(function (response) {
        if (response.data.success == false) {
          toast.error(response.data.message);
        }
        else {
          toast.success(response.data.message)
          setName("");
          navigate('/Category')
        }
      })
      .catch((error) => {
        toast.error(error);
      })
  }






  return (
    <>

      <NavBar />

      <Container fluid style={{ height: '100vh' }}>
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className="p-3">
              <Row style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2 heading" style={{ fontSize: '25px' }}>
                    Category
                  </div>
                  <br />
                  <label className="formleb"> Name</label>

                  <input type="text" className="forminp form-control mb-3" placeholder="Enter name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label className="formleb"> Status</label>
                  <Form.Select aria-label="Default select example" className='mb-3 select forminp'
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}>
                    <option> --select Category --</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>
                  <button type="button" className="formbtn btn btn-primary mt-3" onClick={() => updateCategory(id)}> Save </button>
                </div>
              </Row>
            </Col>
            <Col md={2}>
            </Col>
          </Row>
        </Container>
      </Container>
 
    </>
  );
}
export default EditCategory;