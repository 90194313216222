import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Editblog = () => {
  const { state } = useLocation();

  const [title, setTitle] = useState(state.item.title);
  // const [heading, setHeading] = useState(state.item.heading);
  const [description, setDescription] = useState(state.item.description);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [file, setFile] = useState("");
  const [id, setId] = useState(state.item._id);
  const [status, setStatus] = useState(state.item.status);
  const [writtername, setWrittername] = useState(state.item.writtenBy);

  const navigate = useNavigate()
  useEffect(() => {
    console.log('value:', state.item)
  }, [])

  const updateblog = () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("title", title);
    // formData.append("heading", heading);
    formData.append("description", description);
    formData.append("status", status);
    formData.append("image", file[0]);
    formData.append("writtenBy", writtername);

    axios
      .post(`${BASE_URL}admin/updateblog/${id}`, formData,
        {
          headers: {
            "x-access-token": `${token}`,
            "version": "1.0.0",
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          if (response.data.success == false) {

          } else {
            // setShow(false);
            toast.success(response.data.message);
            navigate("/blogs")
            // getAllHowtouse();
          }
        }).catch((error) => {
          console.log(error);
        })
  }

  // const saveChanges=()=>{
  //   updateHomeCategory();

  //   setShow(false)
  // }
  return (
    <>

      <NavBar />
      <Container fluid >
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className='p-3'>
              <Row className='' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2" style={{ fontSize: '25px' }}>
                  Update Blog

                  </div>
                  <label className='formleb'>Title</label>
                  <Form.Group className=" " controlId="exampleForm.ControlInput1">

                    <Form.Control type="text" placeholder="Enter Name" className='text'
                      name='title'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  {/* <label className='formleb'>Heading</label>
                  <input type='text' className='forminp'
                    name='text'
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                  /> */}

                  <label className='formleb'>Written by</label>
                  <input type='text' className='forminp'
                    name='text'
                    placeholder='Enter the name who written this blog'
                    value={writtername}
                    onChange={(e) => setWrittername(e.target.value)}
                  />


<label className='formleb'>Description</label>

<CKEditor
  className="forminp"
  editor={ClassicEditor}
  data={description}
  onReady={editor => {
    // You can store the "editor" and use when it is needed.
    console.log('Editor is ready to use!', editor);
  }}
  onChange={(event, editor) => {
    const data = editor.getData();
    setDescription(data);
    // console.log( data  );
  }}
  onBlur={(event, editor) => {
    console.log('Blur.', editor);
  }}
  onFocus={(event, editor) => {
    console.log('Focus.', editor);
  }}
/>
                  <label className='formleb'>Image</label>
                  <input type='file' className='forminp'
                    name='file'
                    onChange={(e) => setFile(e.target.files)}
                  />

                  <img src={state.item.image} className="img-fluid" width="100px" />
                  <br />
                  <label className="formleb">Status</label>
                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>


                  </select>

                  <button type="button" className="btn btn-primary mt-3" onClick={() => updateblog()}> Save </button>

                </div>
              </Row>


            </Col>
            <Col md={2}>
            </Col>
          </Row>


        </Container>
      </Container>


    </>
  );
}
export default Editblog;