import NavBar from "../../NavBar";
import { useLocation } from "react-router-dom";
const Viewuser = () => {
    const state = useLocation();
    const item = state.state;
    console.log(state.state);

    return (
        <>
            <NavBar />
            <div className='container '>

                <div className='row d-flex justify-content-center align-items-center mt-5'>

                    <div className='col-10 p-5' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div className="dashboard mb-2 text-center" style={{ fontSize: '25px' }}>
                            User details

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className='p-2'>User Name:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.name}
                                    disabled
                                />

                            </div>

                            <div className="col-md-6">
                                <label className='p-2'>Email:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.email}
                                    disabled
                                />

                            </div>
                            <div className="col-md-6">
                                <label className='p-2'>Mobile number:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.mobile_no}
                                    disabled

                                />

                            </div>
                            <div className="col-md-6">
                                <label className='p-2'>Address 1:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.address}
                                    disabled

                                />

                            </div>
                            <div className="col-md-6">
                                <label className='p-2'>Address 2:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.address2}
                                    disabled

                                />

                            </div>

                            {/* <div className="col-md-6">
                                <label className='p-2'>Country:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.country}
                                    disabled

                                />

                            </div> */}

                            <div className="col-md-6">
                                <label className='p-2'>State:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.state}
                                    disabled

                                />

                            </div>

                            <div className="col-md-6">
                                <label className='p-2'>City:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.city}
                                    disabled

                                />

                            </div>


                            <div className="col-md-6">
                                <label className='p-2'>Pin code:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.zip_code}
                                    disabled

                                />

                            </div>

                            <div className="col-md-6">
                                <label className='p-2'>Status:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.status}
                                    disabled

                                />

                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default Viewuser;
