import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useNavigate } from "react-router-dom";
const Category = () => {
  // const navigate = useNavigate()
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [getallcategory, setGetAllCategory] = useState([]);
  // const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  // const handleClose = () => setShow(false);

  useEffect(() => {
    getAllCat();
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      // navigate("/SignIn");
    }
  }, [token]);

  const Save = async (data) => {
    if (name.length == 0 && name == "") {
      toast.error("Category name is required");
      return;
    }
    axios
      .post(
        `${BASE_URL}admin/addCategory`,
        {
          name: name,
          status: status,
        },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then(function (response) {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setName("");
          setStatus("");
          getAllCat();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setGetAllCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCategory = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {
    } else {
      return;
    }
    const token = localStorage.getItem("token");
    axios
      .put(
        `${BASE_URL}admin/deleteCategory/${id}`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getAllCat();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <NavBar />
      <Container fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <Container>
          <Row className="mb-5">
            <Col md={2}></Col>
            <Col className="p-3">
              <Row style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form">
                  <div
                    className="dashboard mb-2 heading"
                    style={{ fontSize: "25px" }}
                  >
                    Category
                  </div>
                  {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Control type="text" placeholder="Enter name" className="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group> */}
                  <br />
                  <label className="formleb">Name</label>

                  <input
                    type="text"
                    className="forminp form-control mb-3"
                    placeholder="Enter name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label className="formleb">Status</label>

                  <select
                    className="forminp"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option selected={true} value="">
                      --Select status--
                    </option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option></option>
                  </select>
                  <button
                    type="button"
                    className="formbtn btn btn-primary mt-3"
                    onClick={() => Save()}
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </Row>
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col className="col-md-1"></Col>
            <Col>
              <div className="scrollit">
                <span
                  style={{ fontSize: "20px", fontWeight: "600" }}
                  className="p-2 heading"
                >
                  Category Listing
                </span>
                {/* <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr> */}
                <div className="" style={{ overflow: "auto" }}>
                  <Table striped bordered hover className="mt-3">
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        {/* <th>Image</th> */}
                        <th> Name</th>

                        <th>status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getallcategory.map((item, index) => (
                        <tr key={index}>
                          {/* <td><Image src={item.icon} style={{ width: '40px' }}></Image></td> */}
                          <td>{++index}</td>
                          <td>{item.name}</td>

                          <td>{item.status}</td>
                          {/* <td>{item.action}</td> */}

                          <td>
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={() =>
                                navigate("/edit_category", { state: { item } })
                              }
                            >
                              <i className="fa fa-edit"></i>{" "}
                            </button>
                            &nbsp; &nbsp;
                            <button
                              type="button"
                              className="btn btn-danger "
                              onClick={() => deleteCategory(item._id)}
                            >
                              <i className="fa fa-trash"></i>{" "}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col className="col-md-1"></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default Category;
