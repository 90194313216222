import NavBar from "../../../NavBar";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const AddProduct = () => {
  const [name, setName] = useState("");
  const [quantity, setquantity] = useState();
  const [price, setPrice] = useState();
  const [shortdes, setShortdes] = useState("");
  const [longdes, setLongdes] = useState("");
  const [file, setFile] = useState([]);
  const [banner, setBanner] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [howtouseid, setHowtouseId] = useState([]);
  const [bestsellcheck, setBestsellcheck] = useState(false);
  const [status, setStatus] = useState("Active");
  const [benefits, setBenefits] = useState("");
  const [howtouse, setHowtouse] = useState("");
  const [Ingredient, setIngredients] = useState("");
  const [allChildSubCategory, setAllChildSubCategory] = useState("");
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allcategory, setAllCategory] = useState([]);
  const [allsubcategory, setAllSubCategory] = useState([]);
  const [allHowtouse, setAllHowtouse] = useState([]);
  const [homecategory, setHomeCategory] = useState([]);
  const [hcategory, setHcategory] = useState("");
  const [ctgryId, setCtgryId] = useState("");
  const [scategoryId, setScategoryId] = useState("");
  const [childCateId, setChildCateId] = useState("");
  const token = localStorage.getItem("token");
  const getAllCat = () => {
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          setAllCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubCategory = () => {
    if (ctgryId) {
      axios
        .post(
          `${BASE_URL}getSubCategoryByCategoryId/${ctgryId}`,
          {},
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "Application/json",
              Version: "1.0.0",
            },
          }
        )
        .then(function (response) {
          if (response.data.success == false) {
            setAllSubCategory("");
          } else {
            setAllSubCategory(response.data.data);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const getChildcat = () => {
    if (scategoryId) {
      console.log("in");
      axios
        .get(`${BASE_URL}admin/childcategorybysubcategory/${scategoryId}`, {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        })
        .then((response) => {
          if (response.data.success == false) {
            console.log(response.data.message);
            setAllChildSubCategory("");
          } else {
            setAllChildSubCategory(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getallhomecategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllHomeCategory`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          setHomeCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddProduct = () => {
    if (!ctgryId) {
      toast.error("Kindly select category.");
      return;
    }
    if (!scategoryId) {
      toast.error("Kindly select sub category.");
      return;
    }
    if (!childCateId) {
      toast.error("Kindly select  child category.");
      return;
    }

    if (name == "" || name.length == 0) {
      toast.error("Product name is required");
      return;
    }

    if (isNaN(price) || price == undefined) {
      toast.error("Please enter valid price. ");
      return;
    }
    if (isNaN(quantity) || price == undefined) {
      toast.error("Please enter valid quantity");
      return;
    }

    if (!longdes) {
      toast.error("Please enter valid long description");
      return;
    }
    if (howtouseid.length == 0) {
      toast.error("Please select steps to use");
    }
    if (!benefits) {
      toast.error("Please enter benefits");
      return;
    }
    if (!(file.length > 0)) {
      toast.error("Please select image For product");
      return;
    }
    const token = localStorage.getItem("token");
    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("price", price);
    fromData.append("quantity", quantity);
    fromData.append("short_decription", shortdes);
    fromData.append("long_decription", longdes);
    fromData.append("benefits", benefits);
    fromData.append("howtouse", howtouse);
    fromData.append("bestseller", bestsellcheck);
    for (let i = 0; i < howtouseid.length; i++) {
      fromData.append("stepstouse", howtouseid[i]._id);
    }
    fromData.append("ingredients", Ingredient);
    fromData.append("image", file[0]);
    if (banner != undefined) {
      fromData.append("banner", banner[0]);
    } else {
      fromData.append("banner", "");
    }
    fromData.append("status", status);
    fromData.append("CategoryId", ctgryId);
    fromData.append("SubCategoryId", scategoryId);
    fromData.append("HomeCategoryId", hcategory);
    fromData.append("ChildCategoryId", childCateId);
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addProduct`, fromData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          setLoading(false);
          toast.error(response.data.message);
        } else {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/ProductListing");
        }
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  };

  const getAllHowtouse = () => {
    axios
      .get(`${BASE_URL}admin/getAllHowToUse`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          setAllHowtouse(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllCat();
    getSubCategory();
    getallhomecategory();
    getChildcat();

    getAllHowtouse();
  }, []);

  useEffect(() => {
    getSubCategory();
  }, [ctgryId]);

  const Fntosetsubc = (value, getSubCategory) => {
    setCtgryId(value);
    if (getSubCategory && typeof getSubCategory === "function") {
      getSubCategory();
    }
  };
  const Fntosetchildc = (value, getChildcat) => {
    setScategoryId(value);
    console.log("Sub Category Id", value);
    if (getChildcat && typeof getChildcat === "function") {
      getChildcat();
    }
  };
  const handleMultiselectChange = (selectedList) => {
    setHowtouseId(selectedList);
  };

  useEffect(() => {
    getChildcat();
  }, [scategoryId]);
  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <h1 className="text-center">Add Product</h1>

            <div className="row">
              <div className="col-md-6">
                <label className="formleb pt-3">Category</label>
                <select
                  name="category"
                  className="form-control forminp"
                  value={ctgryId}
                  onChange={(e) => Fntosetsubc(e.target.value, getSubCategory)}
                >
                  <option value="">--select--</option>
                  {allcategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Subcategory</label>
                <select
                  name="subcatgeory"
                  className="form-control forminp"
                  value={scategoryId}
                  onChange={(e) => Fntosetchildc(e.target.value, getChildcat)}
                >
                  <option value="">--select--</option>
                  {allsubcategory.length > 0 ? (
                    allsubcategory.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <span>no record found</span>
                  )}
                </select>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">ChildCategory</label>
                <select
                  className="form-control forminp"
                  name="childCateId"
                  value={childCateId}
                  onChange={(e) => setChildCateId(e.target.value)}
                >
                  <option value="">--select--</option>
                  {allChildSubCategory.length > 0 &&
                    allChildSubCategory.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">HomeCategory</label>
                <select
                  name="homecategory"
                  className="form-control forminp"
                  value={hcategory}
                  onChange={(e) => setHcategory(e.target.value)}
                >
                  <option value="">--select--</option>
                  {homecategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Step to Use </label>
                <Multiselect
                  options={allHowtouse}
                  displayValue="name"
                  onSelect={handleMultiselectChange}
                  onRemove={handleMultiselectChange}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Product Name</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="name"
                  value={name}
                  placeholder="Enter Product Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3">Price</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="price"
                  value={price}
                  placeholder="Enter Price"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Quantity</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="quantity"
                  value={quantity}
                  placeholder="Enter Quantity"
                  onChange={(e) => setquantity(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Upload Images</label>
                <input
                  type="file"
                  className="form-control forminp"
                  name="file"
                  // value={file}
                  onChange={(e) => setFile(e.target.files)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Upload Banner</label>
                <input
                  type="file"
                  className="form-control forminp"
                  name="banner"
                  // value={file}
                  onChange={(e) => setBanner(e.target.files)}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Short Description</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  data={shortdes}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShortdes(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Long Description</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  data={longdes}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLongdes(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">Benefits</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write benefits here."

                  data={benefits}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setBenefits(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb pt-3">How To Use</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write  here."
                  placeholder="Enter Description To Use The Product."
                  data={howtouse}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setHowtouse(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3">Ingredients</label>

                <CKEditor
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write Ingredients here."
                  placeholder="Enter Ingredients"
                  data={Ingredient}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setIngredients(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3">Status</label>
                <select
                  name="status"
                  className="forminp form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="select">--select--</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>

              <div className="col-md-6">
                <label className="formleb pt-3">
                  Check mark if you want to add this product in
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setBestsellcheck(e.target.checked)}
                    checked={bestsellcheck}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                </div>
              </div>

              <button
                type="submit"
                className="formbtn  mt-3 ms-3 p-2"
                onClick={() => AddProduct()}
              >
                Save
                {loading && <Spinner animation="border" size="sm" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProduct;
