import { Container, Row } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import Container from 'react-bootstrap/Container';
// import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import NavBar from "../../../NavBar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const EditChildcategory = () => {
  const { state } = useLocation();
  console.log("child category", state);
  // const navigate = useNavigate()
  const [name, setName] = useState(state.item.name);
  const [file, setFile] = useState(state.item.image);

  const [status, setStatus] = useState(state.item.status);
  // const [bgimage, setBgImage] = useState(state.item.bg_image);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [id, setId] = useState(state.item._id);
  const [categoryId, setCategoryId] = useState(state.item.CategoryId);
  const [categoryName, setCategoryName] = useState(state.item.category_details[0]?.name);

  const [subcategoryId, setSubCategoryId] = useState(state.item.SubCategoryId);
  const [subcategoryName, setSubCategoryName] = useState(state.item.SubCategory_details[0]?.name);

  // const [allChildCategory, setAllChildCategory] = useState();

  const navigate = useNavigate();

  // useEffect(() => {
  //   getSubCategory();
  // }, [])

  useEffect(() => {
    getAllCat();
    getSubCategory()
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {

      // navigate("/SignIn");
    }
  }, [token])

  const Save = async (data) => {
    if (!categoryId) {
      toast.error("Kindly select category");
      return;
    }
    if (!subcategoryId) {
      toast.error("Kindly select subcategory");
      return;
    }
    if (file[0] == undefined || file[0] == "") {
      toast.error(" Child category  Image  is required");
      return;
    }
   
    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("image", file[0]);
    // fromData.append("bg_image", bgimage[0]);

    fromData.append("CategoryId", categoryId);
    fromData.append("SubCategoryId", subcategoryId);
    fromData.append("status", status);
    axios
      .put(`${BASE_URL}admin/updateChildSubCategory/${id}`, fromData, {

        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          "Version": "1.0.0"
        },

      })
      .then(function (response) {
        if (response.data.success == false) {

          toast.error(response.data.message);
        }
        else {
          toast.success(response.data.message)
          setName("");
          // setFile("");
      navigate("/childcategory")
          getAllCat();
          // setId("");
        }
      })
      .catch((error) => {
        toast.error(error);
      })
  }

  const getSubCategory = () => {
    console.log("in");
    axios
      .get(`${BASE_URL}admin/getSubCategoryByCategoryId/${categoryId}`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          "version": "1.0.0"
        },
      })
      .then(function (response) {
        if (response.data.success == false) {
          setSubCategories("");
        }
        else {
          console.log("setGetAllSubcategory======>",response.data.data);
          setSubCategories(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      })
  }

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          "version": "1.0.0",
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn")
          }
        } else {
          setGetAllCategory(response.data.data);
          console.log("category====>", response.data.data);
        }
      }).catch((error) => {
        console.log(error);
      })
  }




  const Fntosetsubc = (value, getSubCategory) => {
    // console.log("value", value);
    setCategoryId(value);
    if (getSubCategory && typeof getSubCategory === 'function') {
      getSubCategory();
    }
  }

  useEffect(() => {
    getSubCategory();
  }, [categoryId])
  return (
    <>
      <NavBar />
      <Container fluid style={{ height: '100vh', backgroundColor: '#f8f9fe;' }}>
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className="p-3">
              <Row style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2 heading" style={{ fontSize: '25px' }}>
                    Child Category
                  </div>
                  <br />
                  <label className="py-2"> Category</label>
                  <Form.Select aria-label="Default select example" className='mb-3 select forminp'

                    name="categoryId"
                    value={categoryId}
                    onChange={(e) => Fntosetsubc(e.target.value, getSubCategory)}>
                    {/* onChange={(e) => setCategoryId(e.target.value)}> */}


                    <option> --select Category --</option>
                    {
                      getallcategory.map((item, index) => (
                        <option selected={item.name == categoryName ? true : false} value={item._id} key={index}
                        >{item.name}</option>
                      ))
                    }
                  </Form.Select>
                  <label className='formleb'>Subcategory</label>
                  <select name="subcatgeory" className="form-control forminp"
                    value={subcategoryId}
                    onChange={(e) => setSubCategoryId(e.target.value)}
                  >
                    <option value="" >--select--</option>
                    {
                      subCategories.length > 0 ? subCategories.map((item, index) => (
                        <option selected={item.name == subcategoryName ? true : false} value={item._id} key={index}>{item.name}</option>
                      )) : <span>no record found</span>
                    }

                  </select>

                  <br />
                  <label className="formleb">Child Category Name:</label>

                  <input type="text" className="forminp form-control mb-3" placeholder="Enter child category name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label className="formleb"> Image</label>
                  <input type="file" className="forminp form-control file mb-3"
                    name="file"
                    // value={file}
                    onChange={(e) => setFile(e.target.files)}
                  // onChange={(e) => setFile(e.target.file)}
                  ></input>
                  <img src={`${file}`} width={100} height={60} />
                  <br/>
               


                  <label className="formleb"> Status</label>

                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option selected>--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option></option>

                  </select>
                  <button type="button" className="formbtn btn btn-primary mt-3" onClick={() => Save()}> Save </button>

                </div>
              </Row>

            </Col>
            <Col md={2}>
            </Col>
          </Row>


        </Container>
      </Container>

    </>
  );
}
export default EditChildcategory;