import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../NavBar";
import { useNavigate } from "react-router-dom";

const SubscriberList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [subscriber, setSubscriber] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();
  const getAllSubscriber = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllSubscriber/${currentPage}`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success === false) {
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          setSubscriber(response.data.data);
          setPages(response.data.remainingCount);
          console.log(response.data.data);

        }
      }).catch((error) => {
        console.log(error);
      })
  }
  const deleteSubscriber = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {

    } else {

      return;
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/delSubscriber/${id}`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          getAllSubscriber();
        }
      }).catch((error) => {
        console.log(error.message);
      })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllSubscriber(page);

  }
  useEffect(() => {
    getAllSubscriber();
  }, [currentPage])


  return (
    <>
      <NavBar />
      <div className="container p-3">

        <div style={{ overflowX: "auto" }}>
          <div className="scrollit">
            <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2">All User List</span>
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>


            <Table striped bordered hover >
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th>S no.</th>
                  <th>Email</th>
                  <th>created At:</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>

                {
                  subscriber.map((item, index) => (
                    <tr key={index}>
                      <td className="tabletd">
                        {/* <Image src={item.icon} style={{ width: '40px' }}></Image> */}
                        {(currentPage > 1 ? (currentPage - 1) * 10 : 0) + index + 1}

                      </td>
                      <td>
                        {/* <Image src={item.icon} style={{ width: '40px' }}></Image> */}
                        {item.email}
                      </td>
                      {/* <td>
                        {item.mobile_no}
                        </td> */}
                      <td>
                        {item.createdAt}

                      </td>

                      {/* <td>
                        {item.date}
                        
                      </td> */}
                      <td>


                        <button type="button" class="btn btn-danger btn-sm m-1 "
                          onClick={() => deleteSubscriber(item._id)}><i className="fa fa-trash"></i> </button>


                      </td>

                    </tr>

                  ))
                }


              </tbody>
              {/* pagination  */}
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
                    <a class="page-link" href="#" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>


                  {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                    <>
                      <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>
                          {page}
                        </button>
                      </li>
                    </>
                  ))}
                  <li class={`page-item ${currentPage === pages ? "disabled" : ""}`}>
                    <a class="page-link" href="#" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
              {/* pagination end  */}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
export default SubscriberList;