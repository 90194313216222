import Table from "react-bootstrap/Table";
import { Container, Col, Row } from "react-bootstrap";
import NavBar from "../../../NavBar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const ProductListing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allChildSubCategory, setAllChildSubCategory] = useState("");

  const [allcategory, setAllCategory] = useState([]);
  const [allsubcategory, setAllSubCategory] = useState([]);
  const [allHowtouse, setAllHowtouse] = useState([]);
  const [homecategory, setHomeCategory] = useState([]);
  const [hcategory, setHcategory] = useState("");
  const [ctgryId, setCtgryId] = useState("");
  const [scategoryId, setScategoryId] = useState("");
  const [childCateId, setChildCateId] = useState("");
  const [allproducts, setAllProducts] = useState([]);
  // const [show, setShow] = useState(false);
  // const [id , setId]=useState();
  // const [name , setName]=useState("");
  // const [mrp , setMrp]=useState();
  // const [price , setPrice]=useState();
  // const [shortdes, setShortdes]=useState("");
  // const [longdes, setLongdes]=useState("");
  // const [file, setFile]=useState([]);
  // const [howtouseid, setHowtouseId]=useState("");
  // const [quantity , setquantity]=useState("");
  // const [benefits , setBenefits]=useState("");
  // const [howtouse , setHowtouse]=useState("");
  // const [composition , setComposition]=useState("");
  // const [Ingredient , setIngredients]=useState("");
  // const [ shippinginfo , setShippingInfo]=useState("");
  // const [ refundpolicy , setRefundPolicy]=useState("");
  // const [status , setStatus]=useState("");
  const [giftcheck, setgiftCheck] = useState(false);
  const [bestsellcheck, setBestsellcheck] = useState(false);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const getallProducts = () => {
    axios
      .post(
        `${BASE_URL}admin/getAllProduct/${currentPage}`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == false) {
          // toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          // toast.success(response.data.message);
          setAllProducts(response.data.data);
          setPages(response.data.remainingCount);
          console.log("All Products", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllSubCategory = () => {
    axios
      .get(`${BASE_URL}admin/getAllSubCategory`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          Version: "1.0.0",
        },
      })
      .then(function (response) {
        // console.log(response);
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          // toast.success(response.data.message)
          setAllSubCategory(response.data.data);

          // console.log("setGetAllSubcategory",response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const getAllCat = () => {
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          setAllCategory(response.data.data);
          // console.log("getallcategory",response.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChildcat = () => {
    axios
      .get(`${BASE_URL}admin/getAllChildSubCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          setAllChildSubCategory(response.data.data);
          // console.log("all child category",response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getallhomecategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllHomeCategory`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          console.log("homecategory", response.data.data);
          setHomeCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteproduct = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {
    } else {
      return;
    }
    const token = localStorage.getItem("token");

    axios
      .put(
        `${BASE_URL}admin/deleteProduct/${id}`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getallProducts();
          //   setAllProducts(response.data.data);
          //   console.log(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllHowtouse = () => {
    axios
      .get(`${BASE_URL}admin/getAllHowToUse`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          setAllHowtouse(response.data.data);
          // console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getallProducts();

    getAllCat();
    getAllSubCategory();
    getallhomecategory();
    getChildcat();
    getAllHowtouse();
    console.log("bestseller check", bestsellcheck);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getallProducts(page);
  };

  useEffect(() => {
    getallProducts();
  }, [currentPage]);
  return (
    <>
      <NavBar />
      <Container fluid>
        <Container>
          <div className="scrollit">
            <div>
              <Row className="d-flex align-items-center">
                <Col className="text-start">
                  <span
                    style={{ fontSize: "20px", fontWeight: "600" }}
                    className="p-2 mt-3"
                  >
                    Product Category Listing
                  </span>
                </Col>
                <Col className="text-end">
                  <button
                    type="button"
                    className="btn btn-primary mt-3 mb-2 p-2"
                  >
                    <Link
                      to="/AddProduct"
                      style={{
                        color: "#fff",
                        fontSize: 20,
                        textDecoration: "none",
                      }}
                    >
                      Add<i className="fa fa-plus"></i>
                    </Link>{" "}
                  </button>
                </Col>
              </Row>
            </div>

            {/* <hr style={{ width: '230px', fontWeight: '700', color: 'grey' }} className='mb-2'></hr> */}
            <div style={{ overflow: "auto" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th> Name</th>
                    <th>Category name</th>
                    <th>Subcategory name </th>
                    {/* <th>Child category name</th> */}
                    <th>Home category name</th>
                    {/* <th>Mrp</th> */}
                    <th>price</th>

                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allproducts.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {" "}
                        {(currentPage > 1 ? (currentPage - 1) * 10 : 0) +
                          index +
                          1}
                      </td>
                      <td>
                        <img src={item.image} style={{ width: "40px" }} />
                        {/* <img src={item.image[1]} style={{ width: '40px',padding:"10px"}}/>
                                        <img src={item.image[2]} style={{ width: '40px', padding:"10px"}}/>
                                        <img src={item.image[3]} style={{ width: '40px', padding:"10px" }}/> */}
                      </td>
                      <td>{item.name}</td>
                      <td>{item.category_details[0]?.name}</td>
                      <td>{item.subCategory_details[0]?.name}</td>
                      <td>{item.childCategory_details[0]?.name}</td>
                      {/* <td>{item.homeCategory_details[0]?.name}</td> */}
                      {/* <td>{item.mrp}</td> */}
                      <td>{item.price}</td>

                      <td>{item.status}</td>

                      <td>
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={() =>
                            navigate("/updateProduct", { state: { item } })
                          }
                        >
                          <i className="fa fa-edit"></i>{" "}
                        </button>
                        &nbsp; &nbsp;
                        <button
                          type="button"
                          className="btn btn-danger "
                          onClick={() => deleteproduct(item._id)}
                        >
                          <i className="fa fa-trash"></i>{" "}
                        </button>{" "}
                        &nbsp; &nbsp;
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={() =>
                            navigate("/product-details", { state: { item } })
                          }
                        >
                          <i className="fa fa-eye"></i>{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li
                      class={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      } `}
                    >
                      <a
                        class="page-link"
                        href="#"
                        aria-label="Previous"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    {Array.from({ length: pages }, (_, i) => i + 1).map(
                      (page) => (
                        <>
                          <li
                            key={page}
                            className={`page-item ${
                              page === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(page)}
                            >
                              {page}
                            </button>
                          </li>
                        </>
                      )
                    )}
                    <li
                      class={`page-item ${
                        currentPage === pages ? "disabled" : ""
                      }`}
                    >
                      <a
                        class="page-link"
                        href="#"
                        aria-label="Next"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </Table>
            </div>
          </div>
        </Container>
      </Container>

      {/* <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label className='formleb'>Choose Category</label>
<select name="category" className="form-control forminp" 
value={ctgryId} 
onChange={(e)=>setCtgryId(e.target.value)}
>
    <option  value="select">--select--</option>
    {
        allcategory.map((item,index)=>(
          <option value={item._id} key={index}
      
          
          >{item.name}</option>
        ))
      }
</select>


        <label className='formleb'>Choose Subcategory</label>
<select name="subcatgeory" className="form-control forminp" 
value={scategoryId} 
onChange={(e)=>setScategoryId(e.target.value)}
>
    <option  value="select">--select--</option>
    {
        allsubcategory.map((item,index)=>(
          <option value={item._id} key={index}
      
          
          >{item.name}</option>
        ))
      }

</select>
      
        <label className='formleb'>Choose ChildCategory</label>
<select className="form-control forminp" 

name="childCateId" 
value={childCateId} 
onChange={(e)=>setChildCateId(e.target.value)}
>
    <option  value="select">--select--</option>
    {
        allChildSubCategory.length>0&&allChildSubCategory.map((item,index)=>(
          <option value={item._id} key={index}
      
          
          >{item.name}</option>
        ))
      }

</select>
       
        <label className='formleb'>Choose HomeCategory</label>
<select name="homecategory" className="form-control forminp" 
value={hcategory} 
onChange={(e)=>setHcategory(e.target.value)}
>
    <option  value="select">--select--</option>
    {
        homecategory.map((item,index)=>(
          <option value={item._id} key={index}
      
          
          >{item.name}</option>
        ))
      }
</select>
  
        <label className='formleb'>Choose step to use </label>
<select name="howtouse" className="form-control forminp" 
value={howtouseid} 
onChange={(e)=>setHowtouseId(e.target.value)}
>
    <option  value="select">--select--</option>
    {
        allHowtouse.map((item,index)=>(
          <option value={item._id} key={index}
      
          
          >{item.name}</option>
        ))
      }
</select>
      
<label className='formleb'>Product name</label>
<input type="text" className="form-control forminp"
name="name"
value={name}
onChange={(e)=>setName(e.target.value)}

/>

      
<label className='formleb'>MRP</label>
<input type="text" className="form-control forminp"
name="mrp"
value={mrp}
onChange={(e)=>setMrp(e.target.value)}

/>

      
<label className='formleb'>Price</label>
<input type="text" className="form-control forminp"
name="price"
value={price}
onChange={(e)=>setPrice(e.target.value)}

/>

<label className='formleb'>Quantity</label>
<input type="text" className="form-control forminp"
name="quantity"
value={quantity}
onChange={(e)=>setquantity(e.target.value)}

/>

        
<label className='formleb'> images</label>
<input type="file" className="form-control forminp"
name="file"  multiple

onChange={(e)=>setFile(e.target.files)}

/>


  
        <label className='formleb'>Short description</label>

        <CKEditor
        className="forminp"
                    editor={ ClassicEditor }
                    data={shortdes}
                    onReady={ editor => {
                       
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setShortdes(data);
                       
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


  
       
        <label className='formleb'>Long description</label>

        <CKEditor
        className="forminp"
                    editor={ ClassicEditor }
                    data={longdes}
                    onReady={ editor => {
                      
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setLongdes(data);
                     
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


     
      
        <label className='formleb'>Benefits</label>

        <CKEditor
        className="forminp"
                    editor={ ClassicEditor }
                   

                    data={benefits}
                    onReady={ editor => {
                      
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setBenefits(data);
                      
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


      

        <label className='formleb'>How-to-use</label>

<CKEditor
className="forminp"
            editor={ ClassicEditor }
          

            data={howtouse}
            onReady={ editor => {
             
                console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setHowtouse(data);
             
            } }
            onBlur={ ( event, editor ) => {
                console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => {
                console.log( 'Focus.', editor );
            } }
        />





<label className='formleb'>Compositions</label>

<CKEditor
className="forminp"
            editor={ ClassicEditor }
          

            data={composition}
            onReady={ editor => {
              
                console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setComposition(data);
              
            } }
            onBlur={ ( event, editor ) => {
                console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => {
                console.log( 'Focus.', editor );
            } }
        />





<label className='formleb'>Ingredients</label>

<CKEditor
className="forminp"
            editor={ ClassicEditor }
          

            data={Ingredient}
            onReady={ editor => {
             
                console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setIngredients(data);
             
            } }
            onBlur={ ( event, editor ) => {
                console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => {
                console.log( 'Focus.', editor );
            } }
        />




        
        <label className='formleb'>Shipping Info </label>

        <CKEditor
        className="frominp"
                    editor={ ClassicEditor }
                 
                    data={shippinginfo}

                    onReady={ editor => {
                     
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setShippingInfo(data);
                     
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


       
     
        <label className='fromleb'>Refund Policy</label>

        <CKEditor
        className="forminp"
                    editor={ ClassicEditor }
                    data={refundpolicy}
                    onReady={ editor => {
                    
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setRefundPolicy(data);
                    
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


      
      
        <label className='formleb'>Status</label>
<select name="status" className="forminp form-control" 
value={status} 
onChange={(e)=>setStatus(e.target.value)}
>
    <option  value="select">--select--</option>
    <option value="Active">Active</option>
    <option value="Inactive">Inactive</option>

</select>
       

       
        <label className='formleb'>Check mark if you want to add this product in</label>
        <br/>
        <div className='d-flex align-items-center'>
        <input type='checkbox'class="largerCheckbox" onClick={(e)=> setBestsellcheck(e.target.checked)}  checked={bestsellcheck}/>&nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
<input type='checkbox' class="largerCheckbox" onClick={(e)=>setgiftCheck(e.target.checked)} checked={giftcheck}/>&nbsp;&nbsp;&nbsp; <span>Gift and Promotion</span>
        </div>


        

                               
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>UpdateProduct()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default ProductListing;
