import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import NavBar from '../../../NavBar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const ProductDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);
  let state2 = state.item


  return (
    <>
      <NavBar />
      <div className='container '>
        <div className='row d-flex justify-content-center align-items-center mt-5'>
          <div className='col-10 p-5' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
            <div className="row">
              <div className="col-md-6">
                <label className='formleb'>Category</label>
                <input className="form-control forminp" value={state2.category_details[0]?.name} disabled />

              </div>
              <div className="col-md-6">
                <label className='formleb'>Choose Subcategory</label>
                <input className="form-control forminp" value={state2.subCategory_details[0]?.name} disabled />

              </div>
              <div className="col-md-6">
                <label className='formleb'>Child Category</label>
                <input className="form-control forminp" value={state2.childCategory_details[0]?.name} disabled />


              </div>

              <div className="col-md-6">
                <label className='formleb'>Choose HomeCategory</label>
                <input className="form-control forminp" value={state2.homeCategory_details[0]?.name} disabled />


              </div>
              <div className="col-md-6">
                <label className='formleb'>Choose step to use </label>
                <Multiselect
                  // options={state2.howtouses}
                  displayValue="name"
                  onSelect={state2?.howtouses}
                  // onRemove={handleMultiselectChange}
                  selectedValues={state2?.howtouses}
                  disable

                />

              </div>
              <div className="col-md-6">
                <label className='formleb'>Product name</label>
                <input type="text" className="form-control forminp"
                  name="name"
                  value={state2?.name}
                  disabled

                />

              </div>
              <div className="col-md-6">
                <label className='formleb'>MRP</label>
                <input type="text" className="form-control forminp"
                  name="mrp"
                  value={state2?.mrp}
                  disabled
                />

              </div>
              <div className="col-md-6">
                <label className='formleb'>Price</label>
                <input type="text" className="form-control forminp"
                  name="price"
                  value={state2?.price}
                  disabled

                />

              </div>
              <div className="col-md-6">
                <label className='formleb'>Quantity</label>
                <input type="text" className="form-control forminp"
                  name="quantity"
                  value={state2?.quantity}
                  disabled

                />

              </div>
              <div className="col-md-6">
                <label className='formleb'> images</label>
                {
                  state2.image && state2.image.map((item, index) => (
                    <>
                      <img src={item} key={index} className='img-fluid' width={100} height={80} />

                    </>
                  ))
                }


              </div>
              <div className="col-md-6">
                <label className='formleb'>Short description</label>

                <CKEditor
                disabled
                  className="forminp"
                  editor={ClassicEditor}
                  data={state2?.short_decription}
                  onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setShortdes(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>
              <div className="col-md-6">
                <label className='formleb'>Long description</label>

                <CKEditor
                disabled
                  className="forminp"
                  editor={ClassicEditor}
                  data={state2?.long_decription}
                  onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setLongdes(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>
              <div className="col-md-6">
                <label className='formleb'>Benefits</label>

                <CKEditor
                disabled
                  className="forminp"
                  editor={ClassicEditor}

                  data={state2?.benefits}
                  onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setBenefits(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>
              <div className="col-md-6">
                <label className='formleb'>How-to-use</label>

                <CKEditor
                disabled
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write  here."

                  data={state2?.howtouse}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setHowtouse(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>
              <div className="col-md-6">

                <label className='formleb'>Compositions</label>

                <CKEditor
                disabled
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write Compositions here."

                  data={state2?.compositions}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setComposition(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>
              <div className="col-md-6">

                <label className='formleb'>Ingredients</label>

                <CKEditor
                disabled
                  className="forminp"
                  editor={ClassicEditor}
                  // data="write Ingredients here."

                  data={state2?.ingredients}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setIngredients(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>

              <div className="col-md-6">
                <label className='formleb'>Shipping Info </label>

                <CKEditor
                disabled
                  className="frominp"
                  editor={ClassicEditor}
                  // data={description}
                  data={state2?.shipping_info}

                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setShippingInfo(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>
              <div className="col-md-6">
                <label className='fromleb'>Refund Policy</label>

                <CKEditor
                disabled
                  className="forminp"
                  editor={ClassicEditor}
                  data={state2?.refund_policy}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    // const data = editor.getData();
                    // setRefundPolicy(data);
                    // console.log( data  );
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />


              </div>
              <div className="col-md-6">
                <label className='formleb'>Status</label>
                <input type="text" className="form-control forminp"
                  // name="quantity"
                  value={state2?.status}
                  disabled

                />

              </div>

              <div className="col-md-6">
                <label className='formleb'>Check mark if you want to add this product in</label>
                <br />
                <div className='d-flex align-items-center'>
                  <input type='checkbox' class="largerCheckbox"
                  // onClick={(e)=> setBestsellcheck(e.target.checked)}
                  checked={state2?.bestseller}
                  disabled
                  />&nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                  {/* <input type='checkbox' class="largerCheckbox"
                  // onClick={(e)=>setgiftCheck(e.target.checked)} 
                  checked={state2?.giftandpromotion}
                  disabled
                  />&nbsp;&nbsp;&nbsp; <span>Gift and Promotion</span> */}
                </div>


              </div>


              {/* <button type="submit" className="formbtn  mt-3 ms-3 p-2" onClick={()=>AddProduct()} >Save</button> */}
            </div>

          </div>
        </div>


      </div>


      {/* <Container fluid style={{ height: '100vh', backgroundColor: '#f8f9fe;' }}>
                <Container>
                    <Row className="mb-5">
                        <Col md={1}>
                        </Col>
                        <Col >
                            <div className="p-5 mt-5 mb-5 form" >
                                <div className="dashboard mb-2" style={{ fontSize: '25px' }}>
                                    Product
                                    <hr className='m-0 mb-3' style={{ width: '100px' }}></hr>
                                </div>
                                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                                    <Form.Label
                                    >Enter Name:</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Name" className='text' 
                                    name='name'
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                    />
                                </Form.Group>


                                <Form.Control type="file" size="lg" 
                                name='file'
                                onChange={(e)=>setFile(e.target.files)}
                                />
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Short description</Form.Label>
                                    <Form.Control as="textarea" rows={3} className='text'
                                    name='shortdes'
                                    value={shortdes}
                                    onChange={(e)=>setShortdes(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Long description</Form.Label>
                                    <Form.Control as="textarea" rows={3} className='text' 
                                    name='longdes'
                                    value={longdes}
                                    onChange={(e)=>setLongdes(e.target.value)}/>
                                </Form.Group>


                                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                                    <Form.Label>Enter mrp:</Form.Label>
                                    <Form.Control type="number" placeholder="mrp" className='text' 
                                    name='mrp'
                                    value={mrp}
                                    onChange={(e)=>setMrp(e.target.value)}/>
                                </Form.Group>

                                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                                    <Form.Label>Enter Price:</Form.Label>
                                    <Form.Control type="number" placeholder="Price" className='text' 
                                    name='price'
                                    value={price}
                                    onChange={(e)=>setPrice(e.target.value)}/>
                                </Form.Group>


                                <button type="button" class="btn btn-primary mt-3" onClick={()=>AddProduct()}> Save </button>

                            </div>

                        </Col>
                        <Col md={1}>
                        </Col>
                    </Row>


                </Container>
            </Container> */}
    </>
  );
}
export default ProductDetails;