import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Blog = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  // const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [file, setFile] = useState("");
  const [writtername, setWrittername] = useState("");

  // const [id, setId] = useState(state.item._id);
  const [status, setStatus] = useState("Active");
  const fileInputRef = useRef(null);
  const token = localStorage.getItem('token');
  const [blogs, setBlogs] = useState([])

  const getBlogs = () => {
    axios
      .get(`${BASE_URL}admin/getblogs`,
        {
          headers: {
            "Content-Type": "Application/json",
            "version": "1.0.0",
            "x-access-token": `${token}`
          }
        }).then((response) => {
          if (response.data.success == false) { console.log(response.data.message) }
          else {
            setBlogs(response.data.data);
            console.log(response.data.data);

          }

        }).catch((error) => {
          console.log(error);
        })
  }

  const addBlog = () => {
    if (!title ) {
      toast.error("Title is required");
      return;
    }
  
    if (!description ) {
      toast.error("Description is required");
      return;
    }
    if (file[0] == null || file[0] == undefined) {
      toast.error("Please select image/icon to show the step.");
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    // formData.append("heading", heading);
    formData.append("description", description);
    formData.append("image", file[0]);
    formData.append("writtenBy", writtername);




    formData.append("status", status);

    // formData.append("image", file[0]); 

    axios
      .post(`${BASE_URL}admin/addblog`, formData,
        {
          headers: {
            "x-access-token": `${token}`,
            "version": "1.0.0",
            "Content-Type": "multipart/form-data"


          }
        }).then((response) => {
          if (response.data.success == false) {
            // console.log(resp)
          } else {
            toast.success(response.data.message);
            setTitle("");
            // setHeading('');
            setDescription("")
            getBlogs();

            fileInputRef.current.value = "";
            setStatus("");
          }
        }).catch((error) => {
          console.log(error);
        })
  }


  const deleteblog = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {

    } else {

      return;
    }
    // setId(id);
    axios
      .delete(`${BASE_URL}admin/deleteblog/${id}`,
        {
          headers: {
            "version": "1.0.0",
            "Content-Type": "Application/json",
            "x-access-token": `${token}`
          }
        }).then((response) => {
          if (response.data.success == false) {

          } else {
            toast.error(response.data.message);
            getBlogs();
          }
        }).catch((error) => {
          console.log(error);
        })
  }
  useEffect(() => {
    getBlogs();
  }, [])
  return (
    <>
      <NavBar />
      <Container fluid >
        <Container>
          <Row className="mb-5">
            <Col md={2}>
            </Col>
            <Col className='p-3'>
              <Row className='' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form" >
                  <div className="dashboard mb-2" style={{ fontSize: '25px' }}>
                    Blog

                  </div>
                  <label className='formleb'>Title</label>
                  <Form.Group className=" " controlId="exampleForm.ControlInput1">

                    <Form.Control type="text" placeholder="Enter Name" className='text forminp'
                      name='title'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  {/* <label className='formleb'>Heading</label>
                  <input type='text' className='forminp'
                    name='text'
                    placeholder="Enter heading"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                  /> */}

                  <label className='formleb'>Written By</label>
                  <input type='text' className='forminp'
                    name='text'
                    placeholder="Enter the name who written this blog"
                    value={writtername}
                    onChange={(e) => setWrittername(e.target.value)}
                  />


<label className='formleb'>Description</label>

<CKEditor
  className="forminp"
  editor={ClassicEditor}
  data={description}
  onReady={editor => {
    // You can store the "editor" and use when it is needed.
    console.log('Editor is ready to use!', editor);
  }}
  onChange={(event, editor) => {
    const data = editor.getData();
    setDescription(data);
    // console.log( data  );
  }}
  onBlur={(event, editor) => {
    console.log('Blur.', editor);
  }}
  onFocus={(event, editor) => {
    console.log('Focus.', editor);
  }}
/>





                  <label className='formleb'>Image</label>
                  <input type='file' className='forminp'
                    name='file'
                    ref={fileInputRef}
                    onChange={(e) => setFile(e.target.files)}
                  />

                  {/* <img src={state.item.image} className="img-fluid" width="100px" /> */}
                  {/* <br /> */}
                  <label className="formleb">Status</label>
                  <select className="forminp"
                    name='status'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>


                  </select>

                  <button type="button" className="btn btn-primary mt-3" onClick={() => addBlog()}> Save </button>

                </div>
              </Row>
            </Col>
            <Col md={2}>
            </Col>
          </Row>
          <div style={{ overflowX: "scroll", scrollBehavior: "smooth" }}>
            <div className="scrollit">
              <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2"> Listing</span>
              <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>
              <div style={{ overflowX: "scroll", scrollBehavior: "smooth" }}>
                <Table striped bordered hover >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>

                      <th> Image</th>
                      {/* <th> Heading</th> */}
                      <th> Description</th>
                      <th> Written By</th>

                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      blogs.length > 0 && blogs.map((item, index) => (
                        <tr>
                          <td>{++index}</td>
                          <td>{item.title}</td>
                          <td><Image src={item.image} style={{ width: '40px' }}></Image></td>
                          {/* <td>{item.heading}</td> */}
                          <td>{item.description}</td>
                          <td>{item.writtenBy}</td>


                          <td>{item.status}</td>
                          <td>
                            <button type="button" class="btn btn-primary " onClick={() => navigate('/update-blogs', { state: { item } })}><i className="fa fa-edit"></i> </button>
                            &nbsp; &nbsp;
                            <button type="button" class="btn btn-danger " onClick={() => deleteblog(item._id)}><i className='fa fa-trash' ></i> </button>
                          </td>
                        </tr>
                      ))
                    }


                    {/* <tr>
                  <td>3</td>
                
                  <td>gujh</td>
                  <td>Active</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
                  </tbody>
                </Table>
              </div>

            </div>
          </div>
        </Container>
      </Container>
    </>
  );
}
export default Blog;
