import axios from "axios";
import { useEffect, useState } from "react";
import { Image, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useLocation, useNavigate } from "react-router-dom";

const UpdateSlider=()=>{
    const navigate=useNavigate();
    const {state}= useLocation();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [allslider , setAllslider]=useState([]);
    const [name , setName]=useState(state.item.name);
    const [file , setFile]=useState("");
    const [status , setStatus]=useState(state.item.status);
    const [title,setTitle]=useState(state.item.title);
    const [heading1 , setHeading1]=useState(state.item.heading1);
    const [heading2 , setHeading2]=useState(state.item.heading2);
    const [slug , setSlug]=useState(state.item.slug);
    const token=localStorage.getItem("token");
    const [id , setId] =useState(state.item._id);
    const maxFileSize=3000000;
    const updateSlider=()=>{
    // console.log(file[0].size);
        const formData= new FormData();
        formData.append("name",name);
        formData.append("status",status);
        formData.append("slug",slug);
        formData.append("title",title);
        formData.append("heading1",heading1);
        formData.append("heading2",heading2);
        formData.append("image",file[0]);
        if(file[0]=="")
        {
            if(file[0].size>maxFileSize)
            {
             toast.error("uploaded file is too large");
             return;
            }  
        }
      
        // console.log(file);       
        // let api="addSlider";
        // if(id)
        // {
        //  api="updateSlider";
        //  formData.append("id",id);
        // }
      
        axios
        .post(`${BASE_URL}admin/updateSlider/${id}`,formData,{
            headers:{
                "Content-Type":"multipart/formdata",
                "version":"1.0.0",
                "x-access-token":`${token}`
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                toast.error(response.data.message);
            }
            else{
                toast.success(response.data.message);
                // getAllSlider();
                // Navigate()
                navigate('/Slider')
                setId("");
                setName("");
                setFile("");
                setSlug("");
                
            }
        })
    }

    useEffect(()=>{
        console.log("vlllllllu========================>",state);

    },[])

    // const updateSlider=()=>{


    //     axios
    //     .post(`${BASE_URL}admin/updateSlider/${id}`)
    // }
    return(
    <>
     <NavBar />
<div className='container '>
    <div className='row d-flex justify-content-center align-items-center mt-5'>
        <div className='col-10 p-5' style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
      <div className="row">
        <div>
        <div className="dashboard mb-2 heading" style={{ fontSize: '25px' }}>
                  Home Slider

                </div>
        </div>
        <div className="col-md-6 ">
            <label className="formleb">Name</label>
            <input type="text" className=" forminp form-control"
            name="name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            />

        </div>
        <div className="col-md-6 ">
            <label className="formleb">Select image</label>
            <input type="file" className="forminp form-control" accept=".png , .jpeg , .jpg "
            name="file"
           
           onChange={(e)=>setFile(e.target.files)}
            />
                 <img src={state.item.image} className="img-fluid" width="100px"/>


        </div>
        <div className="col-md-6 ">
            <label className="formleb"> Title</label>
            <input type="text" className="forminp form-control" 
            name="title"
           value={title}
           onChange={(e)=>setTitle(e.target.value)}
            />

        </div>
        <div className="col-md-6 ">
            <label className="formleb">Heading 1</label>
            <input type="text" className="forminp form-control" 
            name="heading1"
            value={heading1}
           
           onChange={(e)=>setHeading1(e.target.value)}
            />

        </div>
        <div className="col-md-6 ">
            <label className="formleb">Heading 2</label>
            <input type="text" className="forminp form-control" 
            name="heading2"
           value={heading2}
           onChange={(e)=>setHeading2(e.target.value)}
            />

        </div>

        <div className="col-md-6  ">
            <label className="formleb">Slug</label>
            <input type="text" className="forminp form-control"
            name="slug"
            value={slug}
            onChange={(e)=>setSlug(e.target.value)}
            />

        </div>

        <div className="col-md-6">
            <label className="formleb">Status</label>
            {/* <input type="file" className="form-control"/> */}
            <select className="forminp"
            name="status"
            value={status}
            onChange={(e)=>setStatus(e.target.value)}
            >
                <option>--select--</option>
                <option>Active</option>
                <option>Inactive</option>

            </select>

        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center ">
           
        <button type="button" className=" formbtn "  onClick={()=>updateSlider()} style={{marginTop:"32px",padding:"10px",width:"100%"}}>Save</button>

        </div>
      </div>

        </div>
    </div>


</div>



    </>

        );
}
export default UpdateSlider;